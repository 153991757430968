import starGrey from "../../assets/starGrey.png";
import starGreen from "../../assets/starGreen.png";
import review1 from "../../assets/review1.jpeg";
import review2 from "../../assets/review2.jpeg";
import review3 from "../../assets/review3.jpeg";
import review4 from "../../assets/review4.jpeg";
import review5 from "../../assets/review5.jpg";
import review6 from "../../assets/review6.jpg";
import like from "../../assets/like.svg";
import user from "../../assets/user.png";
import { useState } from "react";

export const Reviews = () => {
  const [showReview, setShowReview] = useState(false);
  return (
    <>
      <p className="reviews__title">Rating and reviews</p>
      <div className="reviews__wrapper">
        <div>
          <p className="reviews__score">4.8</p>
          <div className="star__wrapper">
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGreen} alt="green star" className="green__star" />
            <img src={starGrey} alt="grey star" className="green__star" />
          </div>
          <p className="user__wrapper">
            General
            <img src={user} alt="user icon" width={16} height={16} />
          </p>
          <p className="reviews__number">4625</p>
        </div>
        <div>
          <ul className="lines__list">
            <li className="lines__item">
              <p className="numbers">5</p>
              <div className="first__line desktop_one">
                <div className="first__line--green"></div>
              </div>
            </li>
            <li className="lines__item">
              <p className="numbers">4</p>
              <div className="first__line desktop_two">
                <div className="second__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">3</p>
              <div className="first__line desktop_three">
                <div className="third__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">2</p>
              <div className="first__line desktop_four">
                <div className="fourth__line--green"></div>
              </div>
            </li>

            <li className="lines__item">
              <p className="numbers">1</p>
              <div className="first__line desktop_five">
                <div className="fourth__line--green"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review1}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Yusif</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGrey}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">10.10.2024</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            Gördüyüm ən yaxşı layihə! Dərhal 20.000 manat bonus çıxdı, mən
            slotlara girdim və ard-arda 3 Bonus çıxdı!! Pul dərhal karta
            çəkildi! Duyğularım sərhəd tanımır, indi tez-tez oynayıram!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">BURNING Wins Casino</p>
            <p className="review__description">
              Yusif, rəyiniz üçün təşəkkür edirik! Müştərilərimizin layihəmizdən
              zövq alması bizim üçün çox vacibdir. Komandamız məhsulumuzdan
              maksimum həzz almanızı təmin etmək üçün hər şeyi edir!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review2}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Huseyn</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">09.10.2024</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            sozüm yoxdur. 50 manatdan bir iki saata 7000 topladım ;)) Çoxdandı
            ki, belə bəxtim olmayıb. İndiyə qədər oynadığım ən yaxşı kazino!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">BURNING Wins Casino</p>
            <p className="review__description">
              Hüseyn, müsbət rəyiniz üçün təşəkkür edirik. Biz müştərilərimizi
              razı salmağa çalışırıq!
            </p>
          </div>
        </div>
      </div>
      <div className="item__wrapper">
        <img
          src={review3}
          alt="logo"
          width={48}
          height={48}
          className="item__logo"
        />
        <div>
          <div className="item__flex">
            <div>
              <div className="item__thumb">
                <p className="item__title">Ismail</p>
                <div className="images__wrapper">
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGreen}
                    alt="star green"
                    className="green__star"
                  />
                  <img
                    src={starGrey}
                    alt="star green"
                    className="green__star"
                  />
                </div>
              </div>
              <p className="item__date">08.10.2024</p>
            </div>
            <div className="item__likes">
              <img src={like} alt="" />
              <p className="item__like">0</p>
            </div>
          </div>
          <p className="item__text">
            Mən adətən bütün bu kazinolara etibar etmirəm! Amma bu kazino mənim
            çox xoşuma gəldi! Əylənmək üçün 100 manat atdım və 15 dəqiqəyə 5000
            qazandım oynamağa və pul qazanmağa davam edəcəm!
          </p>
          <div className="review__box">
            <div className="triangle"></div>
            <p className="review__title">BURNING Wins Casino</p>
            <p className="review__description">
              İsmayıl, gözəl rəyiniz üçün təşəkkür edirik! Oynayın və həzz alın!
            </p>
          </div>
        </div>
      </div>
      {showReview && (
        <>
          <div className="item__wrapper">
            <img
              src={review4}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Саид</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">07.10.2024</p>
                </div>
                {/* <div className="item__likes">
                <img src={like} alt="" />
                <p className="item__like">0</p>
              </div> */}
              </div>
              <p className="item__text">
                Bunun belə möhtəşəm layihə olacağını gözləmirdim! Hörmət və
                hörmət! Həyatımdakı ən səxavətli və səxavətli kazino!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">BURNING Wins Casino</p>
                <p className="review__description">
                  Саид, rəyiniz üçün təşəkkür edirik. Layihəmiz sırf insanlara
                  emosiyalar bəxş etmək üçün yaradılıb. Sizi daha tez-tez
                  sevindirməyə çalışacağıq! Sənə uğurlar!
                </p>
              </div>
            </div>
          </div>
          {/* <div className="item__wrapper">
            <img
              src={review5}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Максим Ефремов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">08.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                Насыпало за 5 дней игры очень круто! Спасибо за офигенное
                Kazino!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kazino</p>
                <p className="review__description">
                  Максим Ефремов, спасибо за отзыв! Нам очень важно, чтобы наши
                  клиенты получали удовольствие от нашего проекта.
                </p>
              </div>
            </div>
          </div>
          <div className="item__wrapper">
            <img
              src={review6}
              alt="logo"
              width={48}
              height={48}
              className="item__logo"
            />
            <div>
              <div className="item__flex">
                <div>
                  <div className="item__thumb">
                    <p className="item__title">Олег Мартынов</p>
                    <div className="images__wrapper">
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                      <img
                        src={starGreen}
                        alt="star green"
                        className="green__star"
                      />
                    </div>
                  </div>
                  <p className="item__date">05.04.2024</p>
                </div> */}
          {/* <div className="item__likes">
                  <img src={like} alt="" />
                  <p className="item__like">0</p>
                </div> */}
          {/* </div>
              <p className="item__text">
                У меня нет слов. С 500 рублей поднял 7000 за пару часов ;))
                Давно мне так не фартило. Лучшее Kazino в которое я играл!
              </p>
              <div className="review__box">
                <div className="triangle"></div>
                <p className="review__title">Kazino</p>
                <p className="review__description">
                  Олег Мартынов, благодарим вас за положительный отзыв.
                  Стараемся удовлетворять наших клиентов!
                </p>
              </div>
            </div>
          </div> */}
        </>
      )}
      <button
        type="button"
        className="reviews__button"
        onClick={() => {
          setShowReview((prevState) => !prevState);
        }}
      >
        {showReview ? "Hide reviews" : "All reviews"}
      </button>
    </>
  );
};
